.removeGarmentButton {
  margin-top: 32px;
  margin-bottom: 32px;
  justify-content: center;
  align-items: center;
}

.modalVideoView {
  background-color: var(--transparent-black);
  flex-direction: column;
  flex: 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
}
