.regularButton {
  padding: var(--regular-button-padding-top-bottom) var(--regular-button-padding-left-right);
  justify-content: center;
  align-items: center;
}

.regularButtonSmall {
  border-radius: var(--small-button-border-radius);
  padding-top: 8px;
  padding-bottom: 8px;
}

.regularButtonExtraSmall {
  border-radius: var(--extra-small-button-border-radius);
  padding-top: 4px;
  padding-bottom: 4px;
}

.regularButtonBig {
  border-radius: var(--big-button-border-radius);
}

.regularButtonWhite {
  background-color: var(--white);
}
