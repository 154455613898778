.notificationContainer {
  position: fixed;
  flex-direction: column;
  bottom: 15px;
  left: 15px;
  right: 15px;
  z-index: 10;
}

@-webkit-keyframes changeOpacity {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes changeOpacity {
  from {opacity: 0}
  to {opacity: 1}
}

@-webkit-keyframes moveLeft {
  0% {
    transform: translate(100px);
  }
  100% {
    transform: translate(0px);
  }
}

@keyframes moveLeft {
  0% {
    transform: translate(100px);
  }
  100% {
    transform: translate(0px);
  }
}

.swipeBackNotification {
  animation: moveLeft 0.2s, changeOpacity 0.2s;
  -webkit-animation: moveLeft 0.2s, changeOpacity 0.2s;
}

.notificationMainView {
  flex: 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--background-alert);
  padding: 8px;
}

.notificationText {
  padding-left: 8px;
  flex-direction: column;
}

.notificationTitle {
  color: var(--white);
  padding-bottom: 4px;
}
