.settingsBasicInfoTopContainer {
  flex-direction: column;
  height: var(--screen-height-03);
  justify-content: center;
  align-items: center;
}

.logout {
  position: absolute;
  top: 16px;
  right: 16px;
  align-items: flex-end;
}

.userPhoto {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-bottom: 8px;
}
