.closetContainer {
  flex: 1;
}

.closetScrollContainer {
  flex: 1;
  flex-direction: column;
  width: var(--screen-width);
  padding-top: 70px;
  overflow: auto;
}
