.reviewContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  align-items: flex-end;
}

.reviewContent {
  overflow-y: scroll;
  flex-direction: column;
  background-color: var(--white);
  padding-left: var(--review-content-padding-left-right);
  padding-right: var(--review-content-padding-left-right);
  padding-top: 32px;
  padding-bottom: 16px;
}

.reviewQuestionContainer {
  padding: 16px;
  padding-top: 25px;
  padding-bottom: 25px;
  flex: 1;
  flex-direction: column;
}
