.searchScreenContainer {
  padding: var(--search-padding);
  flex: 1;
  flex-direction: column;
  height: var(--search-container-height);
  background-color: var(--background);
  z-index: 11;
  top: 0;
}

.searchInputContainer {
  flex-direction: row;
  align-items: center;
  height: var(--search-input-container-height);
}

.searchInput {
  flex: 1;
  background-color: var(--background);
  border: none;
  padding-left: 8px;
}

.searchButton {
  width: 80%;
  justify-content: center;
}
