.passItOnGarmentContainer {
  flex-direction: row;
  background-color: var(--white);
  margin: 0;
  margin-top: 16px;
}

.passItOnGarmentPhotoContainer {
  padding: var(--pass-it-on-item-padding);
}

.passItOnGarmentPhoto {
  width: var(--pass-it-on-garment-photo-width);
  height: var(--pass-it-on-garment-photo-width);
  background-color: var(--white);
}

.passItOnStatusContent {
  width: calc(var(--list-item-width) - 2 * var(--list-item-content-padding));
  justify-content: flex-start;
}

.passItOnStatusText {
  align-self: center;
  margin-left: 10px;
}

.passItOnStatusPhoto {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 16px;
  top: 16px;
}
