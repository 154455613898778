.closetListItemContainer {
  flex: 1;
  margin: 4px;
}

.closetListItemMainContent {
  flex: 1;
  margin: 4px;
  background-color: var(--white);
}

.closetListItemGarmentPhotoContainer {
  flex: 1;
  justify-content: flex-end;
}

.closetListItemGarmentPhoto {
  height: var(--list-item-garment-photo-height);
}

.closetListItemGarmentHeadline {
  position: absolute;
  flex-direction: column;
  top: 24px;
  left: 16px;
  width: var(--screen-width-07);
}

.closetListItemActions {
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.closetListItemAction {
  align-items: center;
  background-color: var(--white);
  border-radius: var(--small-button-border-radius);
  padding: 6px;
  padding-left: var(--regular-button-padding-left-right);
  padding-right: var(--regular-button-padding-left-right);
}

.shareWashButtonPadding {
  padding-left: 5px;
}
