.gradientButton {
  padding: 16px;
  justify-content: center;
  align-items: center;
}

.gradientButtonSmall {
  border-radius: var(--small-button-border-radius);
  padding-top: 8px;
  padding-bottom: 8px;
}

.gradientButtonExtraSmall {
  border-radius: var(--extra-small-button-border-radius);
  padding-top: 4px;
  padding-bottom: 4px;
}

.gradientButtonBig {
  border-radius: var(--big-button-border-radius);
}
