.emptyImageContainerSmall {
  flex: 1;
  width: var(--screen-width-quarter);
  height: var(--screen-width-quarter);
}

.emptyImageContainer {
  flex: 2;
  width: var(--screen-width-half);
  height: var(--screen-width-half);
}

.emptyTextContainer{
  margin-top: 32px;
  align-items: center;
  flex-direction: column;
}

.emptyTitle {
  text-align: center;
  margin-bottom: 8px;
}
