.binsListMainView {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background);
}

.binListItem {
  flex-direction: row;
  padding: 4px;
  margin-top: 3px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
}
