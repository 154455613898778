.garmentSupplyChainContainer {
  background-color: var(--white);
  padding: 16px;
  width: var(--suply-chain-container-width);
  flex-direction: column;
}

.storyPhoto {
  margin-bottom: 30px;
}

.mapPhoto {
  width: var(--suply-chain-container-width);
  height: 130px;
}

.supplyChainPhotoContainer {
  position: absolute;
  left: 30px;
  border: 4px solid var(--white);
  border-radius: 8px;
  background-color: var(--white);
}

.supplyChainPhoto {
  border-radius: 4px;
  width: var(--supply-chain-photo);
  height: var(--supply-chain-photo);
  z-index: 2;
}

.supplyChainInfo {
  margin-left: var(--suplly-chain-info-margin-left);
  margin-bottom: 5px;
  margin-top: 8px;
}

.supplyChainType {
  margin-left: 0;
  margin-bottom: 4px;
  padding: 0;
  padding-top: 8px;
}

.readButton {
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.readButtonText {
  color: var(--tertiary);
  letter-spacing: 0;
  margin-top: 0;
}

.timeline {
  position: absolute;
  top: 158px;
  left: 28px;
  bottom: 278px;
  right: 0;
  width: 3px;
  background-color: var(--tertiary);
  z-index: 2;
}

.markers {
  position: absolute;
  border-radius: 50%;
  border-color: var(--tertiary);
  width: 13px;
  height: 13px;
  left: 7px;
  top: 12px;
  background-color: var(--tertiary);
  z-index: 2;
}
