.loginContainer {
  flex: 1;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  padding: var(--create-account-padding);
  z-index: 10;
}

.loginImageContainer {
  justify-content: center;
  width: var(--create-account-content-width);
  height: var(--screen-height-015);
}

.createAccountImage {
  margin-right: 8px;
}

.signInButton {
  width: 90%;
  justify-content: center;
}

