.settingsAddressesMainContainer {
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.settingsAddressListItem {
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding: var(--settings-item-padding);
}

.settingsAddressAddNewButton {
  flex-direction: column;
  justify-content: center;
  margin: 32px;
  margin-top: 8px;
}
