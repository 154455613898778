.alertContainer {
  position: fixed;
  flex-direction: column;
  border-top-width: 0;
  z-index: 10;
  bottom: 15px;
  left: 15px;
  right: 15px;
  -webkit-box-shadow: 0px 0.2px 7px 0.3px var(--quiet);
  -moz-box-shadow: 0px 0.2px 7px 0.3px var(--quiet);
  box-shadow: 0px 0.2px 7px 0.3px var(--quiet);
}

.alertMainView {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px;
  padding-bottom: 0;
}

.backgroundAlert {
  flex: 1;
  background-color: var(--background-alert);
}

.alertTextView {
  flex: 1;
  flex-direction: column;
  margin-left: 4px;
  padding: 8px;
}

.alertButtonsView {
  flex-direction: 'row';
  padding-top: 8px;
  justify-content: space-between;
}

.alertOkButtonText {
  padding-left: 32px;
  padding-right: 32px;
}

.alertOkButtonDelete {
  background-color: var(--danger);
}

.alertOkButtonDeleteText {
  padding-left: 16px;
  padding-right: 16px;
}
