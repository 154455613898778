.washingListMainContainer {
  flex: 1;
  position: absolute;
  flex-direction: column;
  height: calc(var(--screen-height));
  background-color: var(--background);
  z-index: 5;
}

.garmentListContainer {
  flex-direction: column;
  width: var(--screen-width);
  padding-bottom: 86px;
  padding-top: 70px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.washingListButtons {
  flex-direction: row;
  position: fixed;
  right: 16px;
  bottom: 16px;
  left: 16px;
  margin-top: 16px;
  justify-content: space-between;
}

.washingListButton {
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
  -webkit-box-shadow: 0px 0.2px 7px 0.3px var(--robins-egg-dark);
  -moz-box-shadow: 0px 0.2px 7px 0.3px var(--robins-egg-dark);
  box-shadow: 0px 0.2px 7px 0.3px var(--robins-egg-dark);
}

.washingListButtonText {
  color: var(--black);
  padding-left: 8px;
}
