.garmentPreviewContent {
  flex-direction: row;
  width: var(--review-garment-preview-content-width);
  padding: var(--garment-preview-padding);
  align-items: flex-start;
}

.garmentPreviewImage {
  width: var(--garment-preview-image-width);
  height: var(--garment-preview-image-height);
}

.garmentPreviewText {
  justify-content: space-around;
  flex-direction: column;
  width: var(--garment-preview-text-width);
  padding: 8px;
}