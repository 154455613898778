.passItOnMainContainer {
  flex: 1;
  justify-content: center;
  height: var(--screen-height-without-tab-nav);
}

.passItOnHeading {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  padding-top: 20px;
  margin-bottom: 24px;
}

.passItOnButtons {
  flex: 1;
  flex-direction: column;
  justify-content: center;
}
