.searchResultsContainer {
  flex: 1;
  height: calc(var(--screen-height) - 70px);
}

.searchResultsMainContent {
  flex: 1;
  flex-direction: column;
  margin-top: 70px;
  width: var(--screen-width);
}

.fullSearchResultsContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  flex-shrink: 0;
  flex-direction: column;
}

.brandPhotoList {
  overflow-x: scroll;
  overflow-y: hidden;
  flex-shrink: 0;
  white-space: nowrap;
  background-color: var(--white);
  margin: 8px;
  padding: 8px;
}

.brandPhoto {
  flex-shrink: 0;
  width: 100px;
  height: 50px;
  margin-right: 8px;
}

.brandPhotoListGradient {
  position: absolute;
  width: 40px;
  height: 50px;
  right: 8px;
  top: 81px;
}
