.closetGridItemContainer {
  width: var(--closet-grid-item-width);
}

.gridItemPhoto {
  width: var(--closet-photo-grid-height);
  height: var(--closet-photo-grid-height);
  object-fit: cover;
  margin: 4px;
}

.closetGridItemNameAndBrand {
  flex-direction: column;
}

.toggleWashButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
