.addressHeaderView {
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.deleteAddressButton {
  padding: var(--settings-item-padding);
}

.deleteAddressButtonLabel {
  color: var(--danger);
  padding: 0;
}

.settingsEditAddressContainer {
  flex: 1;
  flex-direction: column;
  height: var(--screen-height-without-tab-nav);
  overflow-y: scroll;
}

.settingsAddressItem {
  flex-direction: column;
  background-color: var(--white);
  margin-top: 3px;
  padding: var(--settings-item-padding);
}

.editAddressSaveButtonContainer {
  flex-direction: column;
  justify-content: space-evenly;
  padding: var(--settings-item-padding);
  margin-bottom: 32px;
}

.editAddressSaveButtonWarning {
  padding-bottom: 8px;
  justify-content: center;
  color: var(--danger);
}

.editAddressItemInput {
  flex: 1;
  border: none;
  padding: 0;
  align-items: stretch;
  width: 100%;
}
