.garmentCareSection {
  background-color: var(--white);
  margin-bottom: 3px;
}

.binIcon {
  width: 65px;
  height: 65px;
}

.garmentCareSectionText {
  width: var(--screen-width-07);
  flex-direction: column;
}
