.garmentBasicInfoContainer {
  position: relative;
  flex: 0 0 auto;
  overflow: hidden;
  max-width: var(--screen-width);
  height: var(--garment-basic-info-height);
  background-color: var(--white);
  flex-direction: column;
  justify-content: flex-start;
}

.nameAndBrandContainer {
  position: absolute;
  top: 16px;
  left: 16px;
  flex: 0 0 auto;
  flex-direction: column;
  width: var(--screen-width-07);
  z-index: 3;
}

.swiperContainer {
  position: fixed;
  flex: 0 0 auto;
  flex-direction: column;
  height: var(--screen-height);
  z-index: 5;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
}

.garmentPhotoContainer {
  margin-left: var(--garment-basic-photo-margin-left);
}

.garmentPhoto {
  width: var(--garment-basic-photo-width);
  height: 100%;
}

.mainSwiperContainer {
  height: var(--garment-basic-info-swiper-height);
}

.swiper-container {
  flex: 0 0 auto;
}

.swiperGarmentPhoto {
  width: 100vw;
  object-fit: contain;
}

.swiper-pagination {
  width: auto !important;
  height: 65px;
  position: fixed;
  left: 50% !important;
  transform: translate(-50%);
  bottom: 0 !important;
  display: flex;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}

.swiper-pagination-bullet-active {
  background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
}

.garmentToggleWashButton {
  margin-left: 0;
  padding: 0;
  margin-top: 15px;
  flex-direction: row;
  align-items: center;
}

.garmentBottomSection {
  flex: 1;
  height: var(--garment-bottom-section-height);
  background-color: var(--white);
}

.videoButton {
  margin-top: 30px;
}

.videoButtonLabel {
  padding-left: 5px;
  letter-spacing: 1.66px;
}

.addToClosetButton {
  align-self: center;
  margin-top: 30px;
  margin-right: 8px;
}
