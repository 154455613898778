.closetTopMenu {
  justify-content: space-between;
  margin-bottom: 8px;
}

.closetListAndGridIcons {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  margin-right: 16px;
}

.closetLaundryContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.closetLaundryButtonWithSelectedBin {
  padding-right: 25px;
}

.binIconIndicator {
  position: absolute;
  right: -14px;
  top: -6px;
  bottom: 0;
  width: 54px;
  height: 54px;
}

.clearButton {
  padding: 0;
  padding-left: 16px;
}

.modal {
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--background);
}
