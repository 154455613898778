.passItOnItem {
  padding: var(--pass-it-on-item-padding);
  margin: var(--pass-it-on-item-margin) 8px;
  background-color: var(--white);
  flex-direction: 'row';
}

.passItOnItemPhoto {
  margin-top: 12px;
}

.passItOnItemText {
  flex-direction: column;
  padding-left: 8px;
}
