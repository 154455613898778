.brandLogo {
  object-fit: contain;
  width: 100%;
  height: 80px;
}

.garmentInformationSection {
  background-color: var(--white);
  margin-bottom: 3px;
  padding: 16px;
}
.column {
  flex-direction: column;
}

.sectionInRow {
  flex-direction: column;
  width: 50%;
}

.garmentInfoBottom {
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: var(--white);
}

.buttonText {
  flex: 3;
  padding-right: 5px;
}
