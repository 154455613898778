@import url(https://fonts.googleapis.com/css?family=Rubik);
@import url(https://fonts.googleapis.com/css?family=Rubik:500&display=swap);
:root {
  /* colors */
  --background: #f4f4f4;
  --background-alert: #343434;
  --white: #ffffff;
  --black: #000000;
  --quiet: #999999;
  --primary: #9dfbc8;
  --primary-transparent: rgba(157, 251, 200, 0.7);
  --secondary: #f5f186;
  --secondary-transparent: rgba(245,241,134, 0.7);
  --text: #646464;
  --danger: #eb5757;
  --danger-light: #f2c188;
  --robins-egg: #6eeeff;
  --robins-egg-dark: #aa9dfb;
  --transparent: rgba(255,255,255,0);
  --tertiary: #2d9cdb;
  --transparent-black: rgba(21, 21, 21, 0.5);
  --link-blue: #2f80ed;
  --coupon-grey: #c4c4c4;

  /* ----metrics---- */
  --screen-width: 100vw;
  --screen-width-half: calc(var(--screen-width) * 0.5);
  --screen-width-quarter: calc(var(--screen-width) * 0.25);
  --screen-width-07: calc(var(--screen-width) * 0.7);
  --screen-height: calc(var(--vh, 1vh) * 100);
  --screen-height-03: calc(var(--screen-height) * 0.3);
  --screen-height-015: calc(var(--screen-height) * 0.15);
  --screen-height-06: calc(var(--screen-height) * 0.6);
  --screen-height-without-tab-nav: calc(var(--screen-height) - var(--tab-nav-height) );

  --list-item-garment-photo-height: calc(var(--screen-width-07) * 1.25);
  --list-item-padding: 4px;
  --list-item-margin: 8px;
  --list-item-height: calc(var(--screen-width) * 0.3);
  --list-item-width: calc(var(--screen-width) - var(--list-item-height) - var(--list-item-padding) - var(--list-item-margin));
  --list-item-content-padding: 8px;
  --list-item-text-width: calc(var(--list-item-width) - var(--list-item-content-padding) * 2);
  --search-list-item: calc(var(--list-item-width) * 0.5);

  --tab-nav-height: 62px;
  --tab-nav-border-radius: calc(var(--tab-nav-height) / 2);

  --grid-view-margin: 8px;
  --closet-grid-item-width: calc(var(--screen-width-half) - var(--grid-view-margin));
  --closet-photo-grid-height: calc(var(--closet-grid-item-width) - var(--button-padding) * 2 - 8px);

  --button-padding: 8px;
  --regular-button-padding-top-bottom: 16px;
  --regular-button-padding-left-right: 16px;
  --small-button-border-radius: 20px;
  --big-button-border-radius: 28px;
  --extra-small-button-border-radius: 16px;

  --settings-item-padding: 16px;

  --search-padding: 32px;
  --search-container-height: calc(var(--screen-height) - 2 * var(--search-padding));
  --search-input-container-height: calc(var(--search-container-height) / 3);

  --garment-bottom-section-height: 85px;
  --garment-back-button-height: 80px;
  --garment-basic-info-height: calc(var(--screen-height) - var(--tab-nav-height) - var(--garment-bottom-section-height));
  --garment-basic-photo-width: calc(var(--garment-basic-info-height) * 0.8);
  --garment-basic-photo-margin-left: calc(var(--screen-width) - var(--garment-basic-photo-width) * 0.5);
  --garment-basic-info-swiper-height: calc(var(--garment-basic-info-height) - var(--garment-back-button-height));

  --supply-chain-photo: 70px;
  --suply-chain-container-width: calc(var(--screen-width) - 32px);
  --suplly-chain-info-margin-left: calc(var(--supply-chain-photo) + 50px);

  --empty-screen-container-padding: 32px;

  --create-account-padding: 16px;
  --create-account-content-width: calc(
    var(--screen-width) -
    2 * var(--create-account-padding) -
    2 * var(--empty-screen-container-padding)
  );

  --pass-it-on-margin: 8px;
  --pass-it-on-item-padding: 8px;
  --pass-it-on-item-margin: 2px;
  --pass-it-on-status-text-width: calc(var(--list-item-width) - (var(--list-item-height) / 3));
  --pass-it-on-garment-photo-width: calc(
    var(--list-item-height)
    - 2 * var(--pass-it-on-item-padding)
    - 2 * var(--pass-it-on-item-margin)
  );
  --sell-garment-photo-margin: 4px;
  --sell-garment-photo-width: calc(
    (var(--screen-width) - 2 * var(--pass-it-on-margin) - 6 * var(--sell-garment-photo-margin)) / 3
  );

  --instructions-address-padding: 12px;
  --instructions-address-photo-width: calc(var(--screen-width) * 0.1);
  --instructions-address-text-width: calc(
    var(--screen-width)
    - var(--instructions-address-photo-width)
    - 2 * var(--instructions-address-padding)
  );

  --garment-preview-padding: 5px;
  --scan-footer-height: calc(var(--screen-width) * 0.3);
  --garment-preview-image-width: calc(var(--scan-footer-height) - var(--garment-preview-padding));
  --garment-preview-image-height: calc(var(--scan-footer-height) - var(--garment-preview-padding) * 2);
  --garment-preview-text-width: calc(
    var(--screen-width)
    - var(--scan-footer-height)
    - var(--garment-preview-padding)
    - 64px
  );

  --review-content-padding-left-right: 16px;
  --review-garment-preview-content-width: calc(
    var(--screen-width)
      - 2 * var(--review-content-padding-left-right)
      - 2 * var(--garment-preview-padding));


}

html {
  scroll-behavior: smooth;
}

main, div, a, button, p, input {
  display: flex;
  position: relative;
  margin: 0;
  outline: none;
}

a, a:active {
  color: #000000;
  color: var(--black);
  text-decoration: none;
}

.button {
  border: none;
  cursor: pointer;
}

.button:active {
  opacity: 0.5;
}

.withoutFeedback:active {
  opacity: 1;
}

/* fonts */

p {
  font-family: 'Rubik', sans-serif;
  text-align: left;
}

.mainContent {
  flex-direction: column;
  background-color: #f4f4f4;
  background-color: var(--background);
  flex: 1 1;
  min-height: calc(1vh * 100);
  min-height: var(--screen-height);
}

.content {
  margin-bottom: 62px;
  margin-bottom: var(--tab-nav-height);
}

/* fonts */
.H2 {
  font-size: 19px;
  line-height: 22px;
}

.small {
  font-size: 14px;
  line-height: 16.7px;
  color: #646464;
  color: var(--text);
}

.body {
  font-size: 17px;
  line-height: 22px;
  font-weight: normal;
  font-style: normal;
  color: #646464;
  color: var(--text);
}

.title {
  font-size: 45px;
  line-height: 45px;
  font-weight: 300;
  letter-spacing: 0px;
}

.byline {
  font-size: 19px;
  line-height: 22px;
  color: #999999;
  color: var(--quiet);
}

.buttonLabel {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1.66px;
  font-weight: 500;
  color: #646464;
  color: var(--text);
}

.section-upcase {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.8px;
  padding: 16px;
  padding: var(--settings-item-padding);
}

.introText {
  margin-top: 16px;
  margin-left: 8px;
}

.alt {
  color: #000000;
  color: var(--black);
}

.danger {
  color: #eb5757;
  color: var(--danger);
}

.text {
  color: #646464;
  color: var(--text);
}

.quiet {
  color: #999999;
  color: var(--quiet);
}

.white {
  color: #ffffff;
  color: var(--white);
}

.black {
  color: #000000;
  color: var(--black);
}

.linkBlue {
  color: #2f80ed;
  color: var(--link-blue);
}

.tertiary {
  color: #2d9cdb;
  color: var(--tertiary);
}

/* general */

.textAlignCenter {
  text-align: center;
}

.headerView {
  position: fixed;
  top: 0;
  width: 100vw;
  width: var(--screen-width);
  z-index: 2;
}

.headerMenuContainer {
  flex: 1 1;
  flex-wrap: wrap;
  align-items: center;
  background-color: #ffffff;
  background-color: var(--white);
  border: none;
  border-radius: 25px;
  margin: 8px 16px;
  padding: 10px;
  box-shadow: 0px 0.2px 7px 0.3px #999999;
  box-shadow: 0px 0.2px 7px 0.3px var(--quiet);
}

.headerMenuContent {
  flex: 1 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
}

.gradientGreen {
  background: #9dfbc8;
  background: var(--primary);
  background: linear-gradient(90deg, #9dfbc8 0%, #f5f186 100%);
  background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
}

.gradientBlue {
  background: #6eeeff;
  background: var(--robins-egg);
  background: linear-gradient(90deg, #6eeeff 0%, #aa9dfb 100%);
  background: linear-gradient(90deg, var(--robins-egg) 0%, var(--robins-egg-dark) 100%);
}

.gradientDanger {
  background: #eb5757;
  background: var(--danger);
  background: linear-gradient(90deg, #eb5757 0%, #f2c188 100%);
  background: linear-gradient(90deg, var(--danger) 0%, var(--danger-light) 100%);
}

.gradientTransparent {
  background: rgba(255,255,255,0);
  background: var(--transparent);
  background: linear-gradient(90deg, rgba(255,255,255,0) 0%, #ffffff 100%);
  background: linear-gradient(90deg, var(--transparent) 0%, var(--white) 100%);
}

.gradientGreenTransparent {
  background: rgba(157, 251, 200, 0.7);
  background: var(--primary-transparent);
  background: linear-gradient(90deg, rgba(157, 251, 200, 0.7) 0%, rgba(245,241,134, 0.7) 100%);
  background: linear-gradient(90deg, var(--primary-transparent) 0%, var(--secondary-transparent) 100%);
}

.imageText {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.icon32 {
  width: 32px;
  height: 32px;
}

.disabledButton {
  pointer-events: none;
  color: #646464;
  color: var(--text);
}

.backgroundWhite {
  background-color: #ffffff;
  background-color: var(--white);
}

.regularGradientLine {
  height: 6px;
}

.smallGradientLine {
  height: 3px;
}

.listItemMainContainer {
  flex: 1 1;
  flex-direction: row;
  background-color: #ffffff;
  background-color: var(--white);
  margin: 4px;
  margin: var(--list-item-padding);
  margin-bottom: 0;
  padding: 4px;
  padding: var(--list-item-padding);
}

.listItemGarmentPhoto {
  width: calc(100vw * 0.3);
  width: var(--list-item-height);
  height: calc(100vw * 0.3);
  height: var(--list-item-height);
}

.listItemContent {
  width: calc(100vw - calc(100vw * 0.3) - 4px - 8px);
  width: var(--list-item-width);
  padding: 8px;
  padding: var(--list-item-content-padding);
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.listItemContentPhoto {
  width: calc(calc(100vw * 0.3) / 3);
  width: calc(var(--list-item-height) / 3);
  height: calc(calc(100vw * 0.3) / 3);
  height: calc(var(--list-item-height) / 3);
}

.listItemText {
  width: calc(calc(100vw - calc(100vw * 0.3) - 4px - 8px) - 8px * 2);
  width: var(--list-item-text-width);
  flex-direction: column;
}

.regularButtonYellow {
  background-color: #f5f186;
  background-color: var(--secondary);
}

.backButtonContainer {
    padding: 16px;
    padding-bottom: 32px;
}

.emptyScreenContainer {
  flex: 1 1;
  flex-direction: column;
  background-color: #f4f4f4;
  background-color: var(--background);
  justify-content: flex-start;
  align-items: center;
  padding: 32px;
  padding: var(--empty-screen-container-padding);
  padding-bottom: 80px;
}

  .settingsItemMain {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin-top: 3px;
    background-color: #ffffff;
    background-color: var(--white);
  }

  .withoutBottomPadding {
    padding-bottom: 0;
  }

  .withoutPadding {
    padding: 0;
  }

  .passItOnContainer {
    flex: 1 1;
    justify-content: center;
    margin: 8px;
    margin: var(--pass-it-on-margin);
  }

  .passItOnContent {
    flex-direction: column;
    width: calc(100vw - 2 * 8px);
    width: calc(var(--screen-width) - 2 * var(--pass-it-on-item-padding));
  }

  .flexShrink {
    flex-shrink: 0;
  }

.closetContainer {
  flex: 1 1;
}

.closetScrollContainer {
  flex: 1 1;
  flex-direction: column;
  width: var(--screen-width);
  padding-top: 70px;
  overflow: auto;
}

.closetListItemsContainer {
  flex-grow: 1;
  flex: 1 1;
  flex-wrap: wrap;
  flex-direction: row;
}

.closetItemsGridContainer {
  flex-wrap: wrap;
  margin: var(--grid-view-margin);
}

.closetListItemContainer {
  flex: 1 1;
  margin: 4px;
}

.closetListItemMainContent {
  flex: 1 1;
  margin: 4px;
  background-color: var(--white);
}

.closetListItemGarmentPhotoContainer {
  flex: 1 1;
  justify-content: flex-end;
}

.closetListItemGarmentPhoto {
  height: var(--list-item-garment-photo-height);
}

.closetListItemGarmentHeadline {
  position: absolute;
  flex-direction: column;
  top: 24px;
  left: 16px;
  width: var(--screen-width-07);
}

.closetListItemActions {
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  bottom: 16px;
  left: 16px;
  right: 16px;
}

.closetListItemAction {
  align-items: center;
  background-color: var(--white);
  border-radius: var(--small-button-border-radius);
  padding: 6px;
  padding-left: var(--regular-button-padding-left-right);
  padding-right: var(--regular-button-padding-left-right);
}

.shareWashButtonPadding {
  padding-left: 5px;
}

.closetGridItemContainer {
  width: var(--closet-grid-item-width);
}

.gridItemPhoto {
  width: var(--closet-photo-grid-height);
  height: var(--closet-photo-grid-height);
  object-fit: cover;
  margin: 4px;
}

.closetGridItemNameAndBrand {
  flex-direction: column;
}

.toggleWashButton {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}

.closetTopMenu {
  justify-content: space-between;
  margin-bottom: 8px;
}

.closetListAndGridIcons {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 10px;
  margin-right: 16px;
}

.closetLaundryContainer {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.closetLaundryButtonWithSelectedBin {
  padding-right: 25px;
}

.binIconIndicator {
  position: absolute;
  right: -14px;
  top: -6px;
  bottom: 0;
  width: 54px;
  height: 54px;
}

.clearButton {
  padding: 0;
  padding-left: 16px;
}

.modal {
  position: fixed;
  z-index: 3;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: var(--background);
}

.regularButton {
  padding: var(--regular-button-padding-top-bottom) var(--regular-button-padding-left-right);
  justify-content: center;
  align-items: center;
}

.regularButtonSmall {
  border-radius: var(--small-button-border-radius);
  padding-top: 8px;
  padding-bottom: 8px;
}

.regularButtonExtraSmall {
  border-radius: var(--extra-small-button-border-radius);
  padding-top: 4px;
  padding-bottom: 4px;
}

.regularButtonBig {
  border-radius: var(--big-button-border-radius);
}

.regularButtonWhite {
  background-color: var(--white);
}

.binsListMainView {
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background);
}

.binListItem {
  flex-direction: row;
  padding: 4px;
  margin-top: 3px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--white);
}

.emptyImageContainerSmall {
  flex: 1 1;
  width: var(--screen-width-quarter);
  height: var(--screen-width-quarter);
}

.emptyImageContainer {
  flex: 2 1;
  width: var(--screen-width-half);
  height: var(--screen-width-half);
}

.emptyTextContainer{
  margin-top: 32px;
  align-items: center;
  flex-direction: column;
}

.emptyTitle {
  text-align: center;
  margin-bottom: 8px;
}

.loginContainer {
  flex: 1 1;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--background);
  padding: var(--create-account-padding);
  z-index: 10;
}

.loginImageContainer {
  justify-content: center;
  width: var(--create-account-content-width);
  height: var(--screen-height-015);
}

.createAccountImage {
  margin-right: 8px;
}

.signInButton {
  width: 90%;
  justify-content: center;
}


.gradientButton {
  padding: 16px;
  justify-content: center;
  align-items: center;
}

.gradientButtonSmall {
  border-radius: var(--small-button-border-radius);
  padding-top: 8px;
  padding-bottom: 8px;
}

.gradientButtonExtraSmall {
  border-radius: var(--extra-small-button-border-radius);
  padding-top: 4px;
  padding-bottom: 4px;
}

.gradientButtonBig {
  border-radius: var(--big-button-border-radius);
}

.loadingContainer {
  flex: 1 1;
  width: 100%;
  justify-content: center;
  align-items: center;
}




.searchResultsContainer {
  flex: 1 1;
  height: calc(var(--screen-height) - 70px);
}

.searchResultsMainContent {
  flex: 1 1;
  flex-direction: column;
  margin-top: 70px;
  width: var(--screen-width);
}

.fullSearchResultsContainer {
  overflow-y: scroll;
  overflow-x: hidden;
  flex-shrink: 0;
  flex-direction: column;
}

.brandPhotoList {
  overflow-x: scroll;
  overflow-y: hidden;
  flex-shrink: 0;
  white-space: nowrap;
  background-color: var(--white);
  margin: 8px;
  padding: 8px;
}

.brandPhoto {
  flex-shrink: 0;
  width: 100px;
  height: 50px;
  margin-right: 8px;
}

.brandPhotoListGradient {
  position: absolute;
  width: 40px;
  height: 50px;
  right: 8px;
  top: 81px;
}

.searchListItemButtonContainer {
  justify-content: space-between;
  flex-direction: "row";
  width: var(--list-item-text-width);
}

.searchListItemActions {
  width: var(--search-list-item);
  flex-direction: "row";
  justify-content: flex-start;
  align-items: center;
  padding: var(--list-item-content-padding);
}

.flexEnd {
  justify-content: flex-end;
}

.paddingBetween {
  padding-left: 5px;
}

.searchScreenContainer {
  padding: var(--search-padding);
  flex: 1 1;
  flex-direction: column;
  height: var(--search-container-height);
  background-color: var(--background);
  z-index: 11;
  top: 0;
}

.searchInputContainer {
  flex-direction: row;
  align-items: center;
  height: var(--search-input-container-height);
}

.searchInput {
  flex: 1 1;
  background-color: var(--background);
  border: none;
  padding-left: 8px;
}

.searchButton {
  width: 80%;
  justify-content: center;
}

.removeGarmentButton {
  margin-top: 32px;
  margin-bottom: 32px;
  justify-content: center;
  align-items: center;
}

.modalVideoView {
  background-color: var(--transparent-black);
  flex-direction: column;
  flex: 1 1;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
}

.garmentBasicInfoContainer {
  position: relative;
  flex: 0 0 auto;
  overflow: hidden;
  max-width: var(--screen-width);
  height: var(--garment-basic-info-height);
  background-color: var(--white);
  flex-direction: column;
  justify-content: flex-start;
}

.nameAndBrandContainer {
  position: absolute;
  top: 16px;
  left: 16px;
  flex: 0 0 auto;
  flex-direction: column;
  width: var(--screen-width-07);
  z-index: 3;
}

.swiperContainer {
  position: fixed;
  flex: 0 0 auto;
  flex-direction: column;
  height: var(--screen-height);
  z-index: 5;
  overflow: hidden;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
}

.garmentPhotoContainer {
  margin-left: var(--garment-basic-photo-margin-left);
}

.garmentPhoto {
  width: var(--garment-basic-photo-width);
  height: 100%;
}

.mainSwiperContainer {
  height: var(--garment-basic-info-swiper-height);
}

.swiper-container {
  flex: 0 0 auto;
}

.swiperGarmentPhoto {
  width: 100vw;
  object-fit: contain;
}

.swiper-pagination {
  width: auto !important;
  height: 65px;
  position: fixed;
  left: 50% !important;
  transform: translate(-50%);
  bottom: 0 !important;
  display: flex;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 15px;
  height: 15px;
}

.swiper-pagination-bullet-active {
  background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
}

.garmentToggleWashButton {
  margin-left: 0;
  padding: 0;
  margin-top: 15px;
  flex-direction: row;
  align-items: center;
}

.garmentBottomSection {
  flex: 1 1;
  height: var(--garment-bottom-section-height);
  background-color: var(--white);
}

.videoButton {
  margin-top: 30px;
}

.videoButtonLabel {
  padding-left: 5px;
  letter-spacing: 1.66px;
}

.addToClosetButton {
  align-self: center;
  margin-top: 30px;
  margin-right: 8px;
}

.tabButtons {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--white);
  padding: 8px;
  z-index: 3;
}

.brandLogo {
  object-fit: contain;
  width: 100%;
  height: 80px;
}

.garmentInformationSection {
  background-color: var(--white);
  margin-bottom: 3px;
  padding: 16px;
}
.column {
  flex-direction: column;
}

.sectionInRow {
  flex-direction: column;
  width: 50%;
}

.garmentInfoBottom {
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: var(--white);
}

.buttonText {
  flex: 3 1;
  padding-right: 5px;
}

.garmentCareSection {
  background-color: var(--white);
  margin-bottom: 3px;
}

.binIcon {
  width: 65px;
  height: 65px;
}

.garmentCareSectionText {
  width: var(--screen-width-07);
  flex-direction: column;
}

.garmentSupplyChainContainer {
  background-color: var(--white);
  padding: 16px;
  width: var(--suply-chain-container-width);
  flex-direction: column;
}

.storyPhoto {
  margin-bottom: 30px;
}

.mapPhoto {
  width: var(--suply-chain-container-width);
  height: 130px;
}

.supplyChainPhotoContainer {
  position: absolute;
  left: 30px;
  border: 4px solid var(--white);
  border-radius: 8px;
  background-color: var(--white);
}

.supplyChainPhoto {
  border-radius: 4px;
  width: var(--supply-chain-photo);
  height: var(--supply-chain-photo);
  z-index: 2;
}

.supplyChainInfo {
  margin-left: var(--suplly-chain-info-margin-left);
  margin-bottom: 5px;
  margin-top: 8px;
}

.supplyChainType {
  margin-left: 0;
  margin-bottom: 4px;
  padding: 0;
  padding-top: 8px;
}

.readButton {
  padding: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.readButtonText {
  color: var(--tertiary);
  letter-spacing: 0;
  margin-top: 0;
}

.timeline {
  position: absolute;
  top: 158px;
  left: 28px;
  bottom: 278px;
  right: 0;
  width: 3px;
  background-color: var(--tertiary);
  z-index: 2;
}

.markers {
  position: absolute;
  border-radius: 50%;
  border-color: var(--tertiary);
  width: 13px;
  height: 13px;
  left: 7px;
  top: 12px;
  background-color: var(--tertiary);
  z-index: 2;
}

.labelImages {
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: var(--screen-width);
}

.labelImage {
  border: 1px solid black;
  max-width: 98%;
  margin-top: 20px;
}
.deleteAccountContainer{
  flex-direction: column;
  align-items: flex-start;
  height: var(--screen-height-03);
  padding: 16px;
}

.deleteAccountButton {
  padding-left: 0;
  padding: 0;
}
.settingsBasicInfoTopContainer {
  flex-direction: column;
  height: var(--screen-height-03);
  justify-content: center;
  align-items: center;
}

.logout {
  position: absolute;
  top: 16px;
  right: 16px;
  align-items: flex-end;
}

.userPhoto {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin-bottom: 8px;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 0;
  height: 0;
}

.switch input:active {
  background-image: none;
  outline: 0;
  box-shadow: none;
}


/* The slider */
.sliderSwitch {
  position: absolute;
  cursor: pointer;
  border: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.sliderSwitch:before {
  position: absolute;
  content: "";
  height: 26px !important;
  width: 26px !important;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
}

input:checked + .sliderSwitch {
  background: var(--primary-transparent);
  background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
}


input:checked + .sliderSwitch:before {
  transform: translateX(26px);
}

/* Rounded sliders */
.sliderSwitch.round {
  border-radius: 34px;
}

.sliderSwitch.round:before {
  border-radius: 50%;
}
.washingListMainContainer {
  flex: 1 1;
  position: absolute;
  flex-direction: column;
  height: calc(var(--screen-height));
  background-color: var(--background);
  z-index: 5;
}

.garmentListContainer {
  flex-direction: column;
  width: var(--screen-width);
  padding-bottom: 86px;
  padding-top: 70px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.washingListButtons {
  flex-direction: row;
  position: fixed;
  right: 16px;
  bottom: 16px;
  left: 16px;
  margin-top: 16px;
  justify-content: space-between;
}

.washingListButton {
  border-radius: 25px;
  padding-left: 8px;
  padding-right: 8px;
  box-shadow: 0px 0.2px 7px 0.3px var(--robins-egg-dark);
}

.washingListButtonText {
  color: var(--black);
  padding-left: 8px;
}

.removeGarmentContainer {
  width: var(--list-item-width);
  align-items: flex-end;
  justify-content: flex-end;
}

.removeButton {
  color: var(--danger);
}

.tabNav {
  position: fixed;
  bottom: 0px;
  width: var(--screen-width);
  height: var(--tab-nav-height);
  background-color: var(--white);
  justify-content: space-evenly;
  box-shadow: 0px -0.5px 30px -25px var(--quiet);
  z-index: 4;
}

.tabNavItem {
  flex: 1 1;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  padding-bottom: 6px;
}

.tabNavItemActive {
  flex: 1 1;
  height: 100%;
  justify-content: center;
  background-color: var(--white);
}

.centeredTabNavItems {
  flex: 2 1;
  border-radius: var(--tab-nav-border-radius);
}

.tabNavActive {
  background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
}

.notificationContainer {
  position: fixed;
  flex-direction: column;
  bottom: 15px;
  left: 15px;
  right: 15px;
  z-index: 10;
}

@-webkit-keyframes changeOpacity {
  from {opacity: 0}
  to {opacity: 1}
}

@keyframes changeOpacity {
  from {opacity: 0}
  to {opacity: 1}
}

@-webkit-keyframes moveLeft {
  0% {
    transform: translate(100px);
  }
  100% {
    transform: translate(0px);
  }
}

@keyframes moveLeft {
  0% {
    transform: translate(100px);
  }
  100% {
    transform: translate(0px);
  }
}

.swipeBackNotification {
  animation: moveLeft 0.2s, changeOpacity 0.2s;
  -webkit-animation: moveLeft 0.2s, changeOpacity 0.2s;
}

.notificationMainView {
  flex: 1 1;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--background-alert);
  padding: 8px;
}

.notificationText {
  padding-left: 8px;
  flex-direction: column;
}

.notificationTitle {
  color: var(--white);
  padding-bottom: 4px;
}

.alertContainer {
  position: fixed;
  flex-direction: column;
  border-top-width: 0;
  z-index: 10;
  bottom: 15px;
  left: 15px;
  right: 15px;
  box-shadow: 0px 0.2px 7px 0.3px var(--quiet);
}

.alertMainView {
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px;
  padding-bottom: 0;
}

.backgroundAlert {
  flex: 1 1;
  background-color: var(--background-alert);
}

.alertTextView {
  flex: 1 1;
  flex-direction: column;
  margin-left: 4px;
  padding: 8px;
}

.alertButtonsView {
  flex-direction: 'row';
  padding-top: 8px;
  justify-content: space-between;
}

.alertOkButtonText {
  padding-left: 32px;
  padding-right: 32px;
}

.alertOkButtonDelete {
  background-color: var(--danger);
}

.alertOkButtonDeleteText {
  padding-left: 16px;
  padding-right: 16px;
}

.reviewContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  align-items: flex-end;
}

.reviewContent {
  overflow-y: scroll;
  flex-direction: column;
  background-color: var(--white);
  padding-left: var(--review-content-padding-left-right);
  padding-right: var(--review-content-padding-left-right);
  padding-top: 32px;
  padding-bottom: 16px;
}

.reviewQuestionContainer {
  padding: 16px;
  padding-top: 25px;
  padding-bottom: 25px;
  flex: 1 1;
  flex-direction: column;
}

.garmentPreviewContent {
  flex-direction: row;
  width: var(--review-garment-preview-content-width);
  padding: var(--garment-preview-padding);
  align-items: flex-start;
}

.garmentPreviewImage {
  width: var(--garment-preview-image-width);
  height: var(--garment-preview-image-height);
}

.garmentPreviewText {
  justify-content: space-around;
  flex-direction: column;
  width: var(--garment-preview-text-width);
  padding: 8px;
}
.mainSliderContainer {
  flex-direction: column;
  margin-top: 16px;
  align-items: center;
}

.sliderQuestion:before {
  position: relative !important;
  height: 0 !important;
  width: 0 !important;
}

.sliderQuestion {
  -webkit-appearance: none;
  margin-top: 35px;
  position: relative;
  width: 100%;
  height: 3px;
  background: #9dfbc8;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
  outline: none;
  opacity: 1;
  border-radius: 10px;
}

.sliderQuestion::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0.2px 7px 0.3px #999999;
  border-radius: 50%;
  border: 1px solid white;
  background: white;
  cursor: pointer;
}

.sliderQuestion::-moz-range-thumb {
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  width: 20px;
  height: 20px;
  box-shadow: 0px 0.2px 7px 0.3px #999999;
  border-radius: 50%;
  background: white;
  border: 1px solid white;
  cursor: pointer;
}

.textSliderContainer {
  flex: 1 1;
  margin-top: 32px;
  flex-direction: row;
  justify-content: space-between;
}

.textQuestionContainer {
  flex: 1 1;
  margin-top: 16px;
  align-items: center;
}

.textQuestionContainer::-webkit-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--quiet);
  opacity: 1; /* Firefox */
}

.textQuestionContainer:-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--quiet);
  opacity: 1; /* Firefox */
}

.textQuestionContainer::-ms-input-placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--quiet);
  opacity: 1; /* Firefox */
}

.textQuestionContainer::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--quiet);
  opacity: 1; /* Firefox */
}

.textQuestionContainer:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--quiet);
}

.textQuestionContainer::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--quiet);
}

.textQuestionInput {
  font-size: 17px;
  width: calc(var(--screen-width) - 32px);
  margin-bottom: 8px;
  border: 2px solid var(--primary) !important;
}

.textQuestionInput:focus {
  outline: none;
}

.sendReviewContainer {
  justify-content: center;
  background-color: var(--white);
  padding: 20px 0;
}

.sendReviewButtonContainer {
  padding: 0 20px;
}

.sendReviewText {
  padding: 20px 0;
}
.chooseRewardContent {
  flex: 1 1;
  flex-direction: column;
  margin: 16px;
  height: calc(100% - 2 * 16px);
}

.chooseRewardHeader {
  flex-direction: row;
  justify-content: space-between;
}

.chooseRewardButtonContainer {
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.noChosenRewardText {
  padding-bottom: 10px;
  align-self: center;
  color: var(--danger);
}


.multipleChoiceItem {
  margin: 3px 0;
  padding: 20px 16px;
  border: 2px solid;
  border-radius: 50px;
  background-color: var(--white);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.passItOnInstructionsHeading {
  color: var(--quiet);
  padding: 0;
  padding-top: 32px;
  margin-left: 8px;
  margin-bottom: 16px;
}

.couponPhoto {
  width: var(--screen-width);
}

.couponTextContainer {
  padding: 24px;
  align-items: center;
}

.couponDiscountText {
  font-size: 26px;
  line-height: 26px;
}

.couponDiscountNumber {
  color: var(--black);
  font-weight: 900;
  font-size: 27px;
  line-height: 27px;
  padding-top: 30px;
  padding-bottom: 30px;
  letter-spacing: 8px;
}

.couponUsedLabel {
  flex-direction: row;
  background-color: var(--coupon-grey);
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 8px;
  align-items: center;
}

.couponUsedLabelText {
  color: var(--black);
  padding: 0;
  padding-top: 0;
  padding-left: 4px;
  padding-right: 4px;
}

.passItOnGarmentContainer {
  flex-direction: row;
  background-color: var(--white);
  margin: 0;
  margin-top: 16px;
}

.passItOnGarmentPhotoContainer {
  padding: var(--pass-it-on-item-padding);
}

.passItOnGarmentPhoto {
  width: var(--pass-it-on-garment-photo-width);
  height: var(--pass-it-on-garment-photo-width);
  background-color: var(--white);
}

.passItOnStatusContent {
  width: calc(var(--list-item-width) - 2 * var(--list-item-content-padding));
  justify-content: flex-start;
}

.passItOnStatusText {
  align-self: center;
  margin-left: 10px;
}

.passItOnStatusPhoto {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 16px;
  top: 16px;
}

.instructionsMainContainer {
  background-color: var(--white);
  flex-direction: column;
  margin-top: 4px;
  padding-top: 4px;
}

.instructionsAddress {
  margin: 0;
  flex-direction: row;
  padding: var(--instructions-address-padding);
}

.instructionsAddressPhoto {
  width: var(--instructions-address-photo-width);
  height: var(--instructions-address-photo-width);
}

.instructionsAddressText {
  flex-direction: column;
  width: var(--instructions-address-text-width);
  padding-left: var(--instructions-address-padding);
}

.instructionsAddressButtons {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  margin-top: 16px;
  padding: 0;
}

.downloadLabelButton {
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
}

.markUnpostedButton {
  margin: 0;
  padding: 0;
}

.passItOnMainContainer {
  flex: 1 1;
  justify-content: center;
  height: var(--screen-height-without-tab-nav);
}

.passItOnHeading {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  padding-top: 20px;
  margin-bottom: 24px;
}

.passItOnButtons {
  flex: 1 1;
  flex-direction: column;
  justify-content: center;
}

.passItOnItem {
  padding: var(--pass-it-on-item-padding);
  margin: var(--pass-it-on-item-margin) 8px;
  background-color: var(--white);
  flex-direction: 'row';
}

.passItOnItemPhoto {
  margin-top: 12px;
}

.passItOnItemText {
  flex-direction: column;
  padding-left: 8px;
}

.settingsCharitiesListItemContainer {
  justify-content: space-between;
  padding: 16px;
  margin-top: 3px;
  background-color: var(--white);
}

.settingsAddressesMainContainer {
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
}

.settingsAddressListItem {
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  padding: var(--settings-item-padding);
}

.settingsAddressAddNewButton {
  flex-direction: column;
  justify-content: center;
  margin: 32px;
  margin-top: 8px;
}

.selectItemExtraLinkText {
  color: var(--tertiary);
  padding: 0;
  padding-right: 8px;
}

.addressHeaderView {
  flex: 1 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.deleteAddressButton {
  padding: var(--settings-item-padding);
}

.deleteAddressButtonLabel {
  color: var(--danger);
  padding: 0;
}

.settingsEditAddressContainer {
  flex: 1 1;
  flex-direction: column;
  height: var(--screen-height-without-tab-nav);
  overflow-y: scroll;
}

.settingsAddressItem {
  flex-direction: column;
  background-color: var(--white);
  margin-top: 3px;
  padding: var(--settings-item-padding);
}

.editAddressSaveButtonContainer {
  flex-direction: column;
  justify-content: space-evenly;
  padding: var(--settings-item-padding);
  margin-bottom: 32px;
}

.editAddressSaveButtonWarning {
  padding-bottom: 8px;
  justify-content: center;
  color: var(--danger);
}

.editAddressItemInput {
  flex: 1 1;
  border: none;
  padding: 0;
  align-items: stretch;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

