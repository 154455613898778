.sendReviewContainer {
  justify-content: center;
  background-color: var(--white);
  padding: 20px 0;
}

.sendReviewButtonContainer {
  padding: 0 20px;
}

.sendReviewText {
  padding: 20px 0;
}