.searchListItemButtonContainer {
  justify-content: space-between;
  flex-direction: "row";
  width: var(--list-item-text-width);
}

.searchListItemActions {
  width: var(--search-list-item);
  flex-direction: "row";
  justify-content: flex-start;
  align-items: center;
  padding: var(--list-item-content-padding);
}

.flexEnd {
  justify-content: flex-end;
}

.paddingBetween {
  padding-left: 5px;
}
