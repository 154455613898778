.textQuestionContainer {
  flex: 1;
  margin-top: 16px;
  align-items: center;
}

.textQuestionContainer::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: var(--quiet);
  opacity: 1; /* Firefox */
}

.textQuestionContainer:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--quiet);
}

.textQuestionContainer::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--quiet);
}

.textQuestionInput {
  font-size: 17px;
  width: calc(var(--screen-width) - 32px);
  margin-bottom: 8px;
  border: 2px solid var(--primary) !important;
}

.textQuestionInput:focus {
  outline: none;
}
