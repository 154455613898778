:root {
  /* colors */
  --background: #f4f4f4;
  --background-alert: #343434;
  --white: #ffffff;
  --black: #000000;
  --quiet: #999999;
  --primary: #9dfbc8;
  --primary-transparent: rgba(157, 251, 200, 0.7);
  --secondary: #f5f186;
  --secondary-transparent: rgba(245,241,134, 0.7);
  --text: #646464;
  --danger: #eb5757;
  --danger-light: #f2c188;
  --robins-egg: #6eeeff;
  --robins-egg-dark: #aa9dfb;
  --transparent: rgba(255,255,255,0);
  --tertiary: #2d9cdb;
  --transparent-black: rgba(21, 21, 21, 0.5);
  --link-blue: #2f80ed;
  --coupon-grey: #c4c4c4;

  /* ----metrics---- */
  --screen-width: 100vw;
  --screen-width-half: calc(var(--screen-width) * 0.5);
  --screen-width-quarter: calc(var(--screen-width) * 0.25);
  --screen-width-07: calc(var(--screen-width) * 0.7);
  --screen-height: calc(var(--vh, 1vh) * 100);
  --screen-height-03: calc(var(--screen-height) * 0.3);
  --screen-height-015: calc(var(--screen-height) * 0.15);
  --screen-height-06: calc(var(--screen-height) * 0.6);
  --screen-height-without-tab-nav: calc(var(--screen-height) - var(--tab-nav-height) );

  --list-item-garment-photo-height: calc(var(--screen-width-07) * 1.25);
  --list-item-padding: 4px;
  --list-item-margin: 8px;
  --list-item-height: calc(var(--screen-width) * 0.3);
  --list-item-width: calc(var(--screen-width) - var(--list-item-height) - var(--list-item-padding) - var(--list-item-margin));
  --list-item-content-padding: 8px;
  --list-item-text-width: calc(var(--list-item-width) - var(--list-item-content-padding) * 2);
  --search-list-item: calc(var(--list-item-width) * 0.5);

  --tab-nav-height: 62px;
  --tab-nav-border-radius: calc(var(--tab-nav-height) / 2);

  --grid-view-margin: 8px;
  --closet-grid-item-width: calc(var(--screen-width-half) - var(--grid-view-margin));
  --closet-photo-grid-height: calc(var(--closet-grid-item-width) - var(--button-padding) * 2 - 8px);

  --button-padding: 8px;
  --regular-button-padding-top-bottom: 16px;
  --regular-button-padding-left-right: 16px;
  --small-button-border-radius: 20px;
  --big-button-border-radius: 28px;
  --extra-small-button-border-radius: 16px;

  --settings-item-padding: 16px;

  --search-padding: 32px;
  --search-container-height: calc(var(--screen-height) - 2 * var(--search-padding));
  --search-input-container-height: calc(var(--search-container-height) / 3);

  --garment-bottom-section-height: 85px;
  --garment-back-button-height: 80px;
  --garment-basic-info-height: calc(var(--screen-height) - var(--tab-nav-height) - var(--garment-bottom-section-height));
  --garment-basic-photo-width: calc(var(--garment-basic-info-height) * 0.8);
  --garment-basic-photo-margin-left: calc(var(--screen-width) - var(--garment-basic-photo-width) * 0.5);
  --garment-basic-info-swiper-height: calc(var(--garment-basic-info-height) - var(--garment-back-button-height));

  --supply-chain-photo: 70px;
  --suply-chain-container-width: calc(var(--screen-width) - 32px);
  --suplly-chain-info-margin-left: calc(var(--supply-chain-photo) + 50px);

  --empty-screen-container-padding: 32px;

  --create-account-padding: 16px;
  --create-account-content-width: calc(
    var(--screen-width) -
    2 * var(--create-account-padding) -
    2 * var(--empty-screen-container-padding)
  );

  --pass-it-on-margin: 8px;
  --pass-it-on-item-padding: 8px;
  --pass-it-on-item-margin: 2px;
  --pass-it-on-status-text-width: calc(var(--list-item-width) - (var(--list-item-height) / 3));
  --pass-it-on-garment-photo-width: calc(
    var(--list-item-height)
    - 2 * var(--pass-it-on-item-padding)
    - 2 * var(--pass-it-on-item-margin)
  );
  --sell-garment-photo-margin: 4px;
  --sell-garment-photo-width: calc(
    (var(--screen-width) - 2 * var(--pass-it-on-margin) - 6 * var(--sell-garment-photo-margin)) / 3
  );

  --instructions-address-padding: 12px;
  --instructions-address-photo-width: calc(var(--screen-width) * 0.1);
  --instructions-address-text-width: calc(
    var(--screen-width)
    - var(--instructions-address-photo-width)
    - 2 * var(--instructions-address-padding)
  );

  --garment-preview-padding: 5px;
  --scan-footer-height: calc(var(--screen-width) * 0.3);
  --garment-preview-image-width: calc(var(--scan-footer-height) - var(--garment-preview-padding));
  --garment-preview-image-height: calc(var(--scan-footer-height) - var(--garment-preview-padding) * 2);
  --garment-preview-text-width: calc(
    var(--screen-width)
    - var(--scan-footer-height)
    - var(--garment-preview-padding)
    - 64px
  );

  --review-content-padding-left-right: 16px;
  --review-garment-preview-content-width: calc(
    var(--screen-width)
      - 2 * var(--review-content-padding-left-right)
      - 2 * var(--garment-preview-padding));


}

html {
  scroll-behavior: smooth;
}

main, div, a, button, p, input {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  position: relative;
  margin: 0;
  outline: none;
}

a, a:active {
  color: var(--black);
  text-decoration: none;
}

.button {
  border: none;
  cursor: pointer;
}

.button:active {
  opacity: 0.5;
}

.withoutFeedback:active {
  opacity: 1;
}

/* fonts */
@import url('https://fonts.googleapis.com/css?family=Rubik');
@import url('https://fonts.googleapis.com/css?family=Rubik:500&display=swap');

p {
  font-family: 'Rubik', sans-serif;
  text-align: left;
}

.mainContent {
  flex-direction: column;
  background-color: var(--background);
  flex: 1;
  min-height: var(--screen-height);
}

.content {
  margin-bottom: var(--tab-nav-height);
}

/* fonts */
.H2 {
  font-size: 19px;
  line-height: 22px;
}

.small {
  font-size: 14px;
  line-height: 16.7px;
  color: var(--text);
}

.body {
  font-size: 17px;
  line-height: 22px;
  font-weight: normal;
  font-style: normal;
  color: var(--text);
}

.title {
  font-size: 45px;
  line-height: 45px;
  font-weight: 300;
  letter-spacing: 0px;
}

.byline {
  font-size: 19px;
  line-height: 22px;
  color: var(--quiet);
}

.buttonLabel {
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 1.66px;
  font-weight: 500;
  color: var(--text);
}

.section-upcase {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.8px;
  padding: var(--settings-item-padding);
}

.introText {
  margin-top: 16px;
  margin-left: 8px;
}

.alt {
  color: var(--black);
}

.danger {
  color: var(--danger);
}

.text {
  color: var(--text);
}

.quiet {
  color: var(--quiet);
}

.white {
  color: var(--white);
}

.black {
  color: var(--black);
}

.linkBlue {
  color: var(--link-blue);
}

.tertiary {
  color: var(--tertiary);
}

/* general */

.textAlignCenter {
  text-align: center;
}

.headerView {
  position: fixed;
  top: 0;
  width: var(--screen-width);
  z-index: 2;
}

.headerMenuContainer {
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  background-color: var(--white);
  border: none;
  border-radius: 25px;
  margin: 8px 16px;
  padding: 10px;
  -webkit-box-shadow: 0px 0.2px 7px 0.3px var(--quiet);
  -moz-box-shadow: 0px 0.2px 7px 0.3px var(--quiet);
  box-shadow: 0px 0.2px 7px 0.3px var(--quiet);
}

.headerMenuContent {
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-left: 16px;
}

.gradientGreen {
  background: var(--primary);
  background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
}

.gradientBlue {
  background: var(--robins-egg);
  background: linear-gradient(90deg, var(--robins-egg) 0%, var(--robins-egg-dark) 100%);
}

.gradientDanger {
  background: var(--danger);
  background: linear-gradient(90deg, var(--danger) 0%, var(--danger-light) 100%);
}

.gradientTransparent {
  background: var(--transparent);
  background: linear-gradient(90deg, var(--transparent) 0%, var(--white) 100%);
}

.gradientGreenTransparent {
  background: var(--primary-transparent);
  background: linear-gradient(90deg, var(--primary-transparent) 0%, var(--secondary-transparent) 100%);
}

.imageText {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
}

.icon32 {
  width: 32px;
  height: 32px;
}

.disabledButton {
  pointer-events: none;
  color: var(--text);
}

.backgroundWhite {
  background-color: var(--white);
}

.regularGradientLine {
  height: 6px;
}

.smallGradientLine {
  height: 3px;
}

.listItemMainContainer {
  flex: 1;
  flex-direction: row;
  background-color: var(--white);
  margin: var(--list-item-padding);
  margin-bottom: 0;
  padding: var(--list-item-padding);
}

.listItemGarmentPhoto {
  width: var(--list-item-height);
  height: var(--list-item-height);
}

.listItemContent {
  width: var(--list-item-width);
  padding: var(--list-item-content-padding);
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.listItemContentPhoto {
  width: calc(var(--list-item-height) / 3);
  height: calc(var(--list-item-height) / 3);
}

.listItemText {
  width: var(--list-item-text-width);
  flex-direction: column;
}

.regularButtonYellow {
  background-color: var(--secondary);
}

.backButtonContainer {
    padding: 16px;
    padding-bottom: 32px;
}

.emptyScreenContainer {
  flex: 1;
  flex-direction: column;
  background-color: var(--background);
  justify-content: flex-start;
  align-items: center;
  padding: var(--empty-screen-container-padding);
  padding-bottom: 80px;
}

  .settingsItemMain {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    margin-top: 3px;
    background-color: var(--white);
  }

  .withoutBottomPadding {
    padding-bottom: 0;
  }

  .withoutPadding {
    padding: 0;
  }

  .passItOnContainer {
    flex: 1;
    justify-content: center;
    margin: var(--pass-it-on-margin);
  }

  .passItOnContent {
    flex-direction: column;
    width: calc(var(--screen-width) - 2 * var(--pass-it-on-item-padding));
  }

  .flexShrink {
    flex-shrink: 0;
  }
