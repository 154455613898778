.tabButtons {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--white);
  padding: 8px;
  z-index: 3;
}
