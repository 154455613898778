.instructionsMainContainer {
  background-color: var(--white);
  flex-direction: column;
  margin-top: 4px;
  padding-top: 4px;
}

.instructionsAddress {
  margin: 0;
  flex-direction: row;
  padding: var(--instructions-address-padding);
}

.instructionsAddressPhoto {
  width: var(--instructions-address-photo-width);
  height: var(--instructions-address-photo-width);
}

.instructionsAddressText {
  flex-direction: column;
  width: var(--instructions-address-text-width);
  padding-left: var(--instructions-address-padding);
}

.instructionsAddressButtons {
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0;
  margin-top: 16px;
  padding: 0;
}

.downloadLabelButton {
  margin: 0;
  margin-bottom: 8px;
  padding: 0;
}

.markUnpostedButton {
  margin: 0;
  padding: 0;
}
