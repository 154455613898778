.closetListItemsContainer {
  flex-grow: 1;
  flex: 1;
  flex-wrap: wrap;
  flex-direction: row;
}

.closetItemsGridContainer {
  flex-wrap: wrap;
  margin: var(--grid-view-margin);
}
