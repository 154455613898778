.couponPhoto {
  width: var(--screen-width);
}

.couponTextContainer {
  padding: 24px;
  align-items: center;
}

.couponDiscountText {
  font-size: 26px;
  line-height: 26px;
}

.couponDiscountNumber {
  color: var(--black);
  font-weight: 900;
  font-size: 27px;
  line-height: 27px;
  padding-top: 30px;
  padding-bottom: 30px;
  letter-spacing: 8px;
}

.couponUsedLabel {
  flex-direction: row;
  background-color: var(--coupon-grey);
  position: absolute;
  top: 16px;
  left: 16px;
  padding: 8px;
  align-items: center;
}

.couponUsedLabelText {
  color: var(--black);
  padding: 0;
  padding-top: 0;
  padding-left: 4px;
  padding-right: 4px;
}
