.mainSliderContainer {
  flex-direction: column;
  margin-top: 16px;
  align-items: center;
}

.sliderQuestion:before {
  position: relative !important;
  height: 0 !important;
  width: 0 !important;
}

.sliderQuestion {
  -webkit-appearance: none;
  margin-top: 35px;
  position: relative;
  width: 100%;
  height: 3px;
  background: #9dfbc8;
  background-image: linear-gradient(to right, var(--primary), var(--secondary));
  outline: none;
  opacity: 1;
  border-radius: 10px;
}

.sliderQuestion::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  -webkit-box-shadow: 0px 0.2px 7px 0.3px #999999;
  -moz-box-shadow: 0px 0.2px 7px 0.3px #999999;
  box-shadow: 0px 0.2px 7px 0.3px #999999;
  border-radius: 50%;
  border: 1px solid white;
  background: white;
  cursor: pointer;
}

.sliderQuestion::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  -webkit-box-shadow: 0px 0.2px 7px 0.3px #999999;
  -moz-box-shadow: 0px 0.2px 7px 0.3px #999999;
  box-shadow: 0px 0.2px 7px 0.3px #999999;
  border-radius: 50%;
  background: white;
  border: 1px solid white;
  cursor: pointer;
}

.textSliderContainer {
  flex: 1;
  margin-top: 32px;
  flex-direction: row;
  justify-content: space-between;
}
