.deleteAccountContainer{
  flex-direction: column;
  align-items: flex-start;
  height: var(--screen-height-03);
  padding: 16px;
}

.deleteAccountButton {
  padding-left: 0;
  padding: 0;
}