.labelImages {
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: var(--screen-width);
}

.labelImage {
  border: 1px solid black;
  max-width: 98%;
  margin-top: 20px;
}