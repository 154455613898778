.chooseRewardContent {
  flex: 1;
  flex-direction: column;
  margin: 16px;
  height: calc(100% - 2 * 16px);
}

.chooseRewardHeader {
  flex-direction: row;
  justify-content: space-between;
}

.chooseRewardButtonContainer {
  flex: 1;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.noChosenRewardText {
  padding-bottom: 10px;
  align-self: center;
  color: var(--danger);
}

