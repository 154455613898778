.tabNav {
  position: fixed;
  bottom: 0px;
  width: var(--screen-width);
  height: var(--tab-nav-height);
  background-color: var(--white);
  justify-content: space-evenly;
  -webkit-box-shadow: 0px -0.5px 30px -25px var(--quiet);
  -moz-box-shadow: 0px -0.5px 30px -25px var(--quiet);
  box-shadow: 0px -0.5px 30px -25px var(--quiet);
  z-index: 4;
}

.tabNavItem {
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 6px;
  padding-bottom: 6px;
}

.tabNavItemActive {
  flex: 1 1;
  height: 100%;
  justify-content: center;
  background-color: var(--white);
}

.centeredTabNavItems {
  flex: 2;
  border-radius: var(--tab-nav-border-radius);
}

.tabNavActive {
  background: linear-gradient(90deg, var(--primary) 0%, var(--secondary) 100%);
}
